<!-- eslint-disable max-len -->
<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 hero-pattern flex flex-col justify-center items-center">
    <div class="w-64 flex flex-col justify-center items-center">
      <div class="w-24 h-24 relative">
        <div class="w-24 h-24 rounded-full absolute top-0 left-0" style="border-right-color: transparent;"></div>
        <div class="w-20 h-20 bg-white rounded-full absolute top-2 left-2 flex flex-col items-center justify-center">
          <CheckIcon class="text-green-600" size="36"/>
        </div>
      </div>
      <div class="mt-3 text-white text-lg invisible">Pronto</div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@vue-hero-icons/solid';

export default {
  name: 'loader',
  metaInfo: {
    title: 'Carregamento',
  },
  components: {
    CheckIcon,
  },
  data: () => ({
  }),
  methods: {
  },
  mounted() {},
};
</script>
